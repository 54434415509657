<template>
<div class="dialog__wrap">
   <v-dialog 
        value="true"
        :max-width="$vuetify.breakpoint.lgAndUp ? '600' : '90%'"
        light
        @click:outside="$emit('close')"
        >
        <v-card>
            <v-toolbar
              color="primary"
              dark
            >{{(item && Object.keys(item).length) ? 'Редактирование' : 'Добавление'}} бейджа</v-toolbar>
            <v-form @submit.prevent="validate()" ref="badgeForm" class="py-4 px-5" v-model="valid">
                <v-text-field
                    v-for="lang in supportedLanguages"
                    :key="lang.id"
                    v-model="newBadge.name[lang.code]"
                    outlined
                    :rules="[Rules.maxLength(25), Rules.notEmpty]"
                    background-color="#fff"
                    color="#858585"
                    :messages="`Название ${lang.code.toUpperCase()}`"
                    class="v-input--counted mb-4"
                    counter="25"
                >
                </v-text-field>
                <v-text-field
                    v-for="lang in supportedLanguages"
                    :key="lang.id"
                    v-model="newBadge.url[lang.code]"
                    outlined
                    background-color="#fff"
                    color="#858585"
                    :messages="`Ссылка ${lang.code.toUpperCase()}`"
                    class="v-input--counted mb-4"
                    
                >
                </v-text-field>
                <v-row no-gutters class="flex-nowrap">
                        <v-text-field
                            v-model="newBadge.color"
                            label="Выберите цвет бейджа"
                            outlined
                            :rules="[Rules.color]"
                            hide-details="true"
                            background-color="#FFFFFF"
                        >
                        </v-text-field>
                    
                         <div class="colorPicker flex-shrink-0" @click="colorPicker = true" :style="`background-color: ${newBadge.color}`">
                               
                        </div>
                   
                </v-row>
                   <v-card-actions class="justify-end mt-4 pa-0">
                        <v-btn
                            elevation="0"
                            color="#0A467E"
                            dark
                            tile
                            :ripple="false"
                            type="submit"
                            height="44"
                            class="px-4"
                        >Сохранить</v-btn>
                        <v-btn
                            elevation="0"
                            outlined
                            dark
                            tile
                            color="#0A467E"
                            :ripple="false"
                            height="44"
                            class="px-4"
                            @click="$emit('close')"
                    >Отмена</v-btn>
                    </v-card-actions>
            </v-form>
         
            
          </v-card>
        </v-dialog>
        <v-dialog 
        max-width="fit-content" 
        :value="colorPicker"
        @click:outside="closeColorPicker"
        content-class="colorPickerDialog"
        >
            <v-card
            color="transparent"
            elevation="0"
            max-width="fit-content">    
                <v-icon
                    @click="colorPicker = false" 
                    class="colorPickerDialog__close d-block ml-auto"
                    color="#FFF"
                >
                    mdi-close
                </v-icon>
                <v-color-picker
                    v-model="newBadge.color"
                    class="ma-2"
                    show-swatches
                    hide-inputs
                ></v-color-picker>
            </v-card>
        </v-dialog>
     
        </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: "addBadge",
    props: {
       item: Object
    },
    data: () => ({
        valid: false,
        newBadge: {
            name: {},
            color: "#000000",
            url: {}
        },
        colorPicker: false
     
    }),
    computed: {
        ...mapGetters(['Rules', 'getPromo']),
        supportedLanguages() {
            if(this.getPromo.country && this.getPromo.country.languages && this.getPromo.country.languages.length) {
                return this.getPromo.country.languages.map((el) => ({code: el.code, id: el.id}))
            } 
            return [{code: 'ru', id: 1}, {code: 'en', id: 2}]
        },
    },
    methods: {
        ...mapActions(['createBadges', 'editBadges']),
        getTranslate(lang) {
            if(this.item && Object.keys(this.item).length) {
                return this.item.translates.find((el) => (el.language.id === lang))
            }
            return []
        },
        fillBadgeInfo() {
            if(this.item && Object.keys(this.item).length) {
                 if(this.supportedLanguages && this.supportedLanguages.length) {
                    for (let i = 0; i < this.supportedLanguages.length; i++) {
                        let lang = this.supportedLanguages[i]
                        let translates =  this.getTranslate(lang.id);
                        this.newBadge.name[lang.code] = translates ? translates.name : "";
                        this.newBadge.url[lang.code] = translates ? translates.url : "";
                    }
            }
                this.newBadge.color = this.item.color
            }
        },
        fillFormData() {
            let translates = [];
            for (let lang of this.supportedLanguages) {
                translates.push({
                    language: lang.id,
                    name: this.newBadge.name[lang.code],
                    url: this.newBadge.url[lang.code],
                })
            }
            return {
                translates: translates,
                name: this.newBadge.name.ru,
                color: this.newBadge.color
            }
        },
        async validate() {
            this.$refs.badgeForm.validate()
            if(this.valid) {
                if(this.item && Object.keys(this.item).length) {
                    await this.editBadges({body: this.fillFormData(), id: this.item.id})
                } 
                else {
                    await this.createBadges(this.fillFormData())
                }
                this.$emit('close')
            }
        },
        closeColorPicker() {
            this.colorPicker = false
        },
    },
    mounted() {
        this.fillBadgeInfo()
    }
}
</script>

<style lang="scss" scoped>
    .dialog__wrap {
        position: relative;
    }

    .colorPicker {
        position: relative;
        width: 56px;
        height: 56px;
        border-radius: 3px;
        border: 1px solid #DADADA;
        cursor: pointer;
    }

    .v-color-picker {
        width: 300px; 
        max-width: 100% !important;
        z-index: 999999;
        @media screen and (max-width: 600px) {
            margin-left: 0;
            margin-right: 0;
        }
    }
</style>

<style lang="scss">
    .colorPickerDialog {
        box-shadow: none;
        overflow: hidden;

        &__close {
            justify-self: flex-end;
        }
    }
</style>